/* HomePageBanner Css */

.background-home-banner {
  background-image: url(../../src/img/MicrosoftTeams-image.png);
  margin-top: 80px;
  height: 761px;
  background-repeat: no-repeat;
  width: 100%;
  background-size: cover;
  position: relative;
}
.home-banner-sub-div {
  text-align: center;
  margin-top: 0px;
  height: 761px;
  width: 100%;
  padding: 260px 102px;
  border-radius: 0px;
  background-color: rgba(0, 22, 39, 0.652576);
  /* padding: 75px 102px; */
  /* width: 901px; */
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  top: 50%;
  transform: translateY(-50%);
}
.home-banner-main-title {
  white-space: nowrap;
  color: rgba(255, 255, 255, 1);
  font-family: "Lato", sans-serif;
  font-size: 55px;
  font-weight: 700;

  letter-spacing: 0px;
  line-height: 60px;
}
.home-banner-main-text {
  margin-top: 18px;
  color: rgba(255, 255, 255, 1);
  font-family: "Lato", sans-serif;
  font-size: 18px;
  font-weight: 400;

  letter-spacing: 0px;
  text-align: center;
  line-height: 32px;
}
.home-banner-main-button {
  margin-top: 43px;
  margin-left: auto;
  margin-right: auto;
  width: 192px;
  height: 53px;
  display: block;
  align-items: center;
  border-radius: 4px;
  border: none;
  background-color: rgba(34, 131, 251, 1);
  color: rgba(255, 255, 255, 1);
  font-family: "Lato", sans-serif;
  font-size: 18px;
  font-weight: 400;

  letter-spacing: 0px;
  text-align: center;
}

/* venture content starts here */

.home-venture-content {
  display: flex;
  flex-direction: row;
  position: absolute;
  width: 46%;
  height: 126px;
  bottom: -60px;
  right: 0;
  border-radius: 4px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  box-shadow: 50px 50px 100px rgba(0, 0, 0, 0.145947);
  background-color: rgba(236, 244, 255, 1);
}
.home-venture-detail {
  color: rgba(0, 48, 86, 1);
  font-family: "Lato", sans-serif;
  margin-top: 37px;
  margin-left: 60px;
  margin-bottom: 33px;
  margin-right: 75px;
  font-size: 24px;
  font-weight: 400;

  letter-spacing: 0px;
  text-align: center;
  line-height: 28px;
}
.home-venture-logo {
  opacity: 1;
  margin-right: 50px;
}

/* Finance Feature CSS */

.finance-feature {
  box-shadow: 6px 6px 15px rgba(159, 164, 194, 0.4);
  background-color: rgba(236, 244, 255, 1);
  height: 327px;
  padding: 128px 0px 79px 0px;
}
.finance-feature-container {
  text-align: center;
}
.finance-content p {
  color: rgba(34, 131, 251, 1);
  font-family: "Lato", sans-serif;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0px;
  text-align: center;
  margin-top: 18px;
}

/* WHY BARLOW MARSHALL? */

.why-Barlow-Marshall {
  height: 955px;
  background-color: rgba(242, 243, 248, 1);
}
.section-title {
  color: rgba(0, 48, 86, 1);
  font-family: "Lato", sans-serif;
  font-size: 28px;
  font-weight: 700;
  letter-spacing: 0px;
  text-align: center;
  line-height: 42px;
  /* margin-top: 379px;
  margin-bottom: 534px; */
}
.common-background {
  border-radius: 4px;
  background: linear-gradient(
    90deg,
    rgba(7, 200, 249, 1) 0%,
    rgba(13, 65, 225, 1) 100%
  );
  height: 292px;
  padding-left: 21px;
  padding-right: 21px;
}

.section-first {
  margin-top: 100px;
  margin-bottom: 30px;
}
.section-second {
  margin-top: 241px;
  margin-bottom: 30px;
}
.common-image {
  padding-top: 30px;
}
.common-title {
  margin-bottom: 20px;
  color: rgba(255, 255, 255, 1);
  font-family: "Lato", sans-serif;
  font-size: 18px;
  font-weight: 700;

  letter-spacing: 0px;
  text-align: left;
}
.common-text {
  color: rgba(255, 255, 255, 1);
  font-family: "Lato", sans-serif;
  font-size: 16px;
  font-weight: 400;

  letter-spacing: 0px;
  text-align: left;
  line-height: 28px;
}
.section-third-text {
  padding-bottom: 30px;
}

/* Founder Friendly CSS */

.founder-friendly-main {
  padding: 100px 0px;
  background-color: RGB(0, 48, 86, 1);
}
.founder-friendly-header {
  margin-bottom: 60px;
}
.founder-friendly-header h1 {
  color: rgba(255, 255, 255, 1);
  font-family: "Lato", sans-serif;
  font-size: 36px;
  font-weight: 700;

  letter-spacing: 0px;
  text-align: center;
  line-height: 51px;
}
.founder-friendly-header p {
  color: rgba(255, 255, 255, 1);
  font-family: "Lato", sans-serif;
  font-size: 16px;
  font-weight: 400;

  letter-spacing: 0px;
  text-align: center;
  line-height: 28px;
}
.founder-content {
  max-width: 371px;
  height: 350px;
  border-radius: 4px;
  border: 1px solid rgba(34, 131, 251, 1);
  background-color: rgba(236, 244, 255, 1);
  padding: 45px;
}
.founder-content-left {
  margin-left: auto;
  margin-right: 18px;
}
.founder-content-right {
  margin-left: 18px;
  margin-right: auto;
}
.founder-content p {
  color: rgba(108, 108, 108, 1);
  font-family: "Lato", sans-serif;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0px;
  text-align: justify;
  line-height: 28px;
}

/* Finance Growth */

.finance-barlow {
  background-color: RGB(0, 48, 86, 1);
  padding-top: 100px;
  padding-bottom: 100px;
}
.finance-container {
  max-width: 1135px;
}
.finace-image {
  width: 100%;
  height: 411px;
}
.section-title-growth {
  padding-left: 120px;
  color: rgba(255, 255, 255, 1);
  font-family: "Lato", sans-serif;
  font-size: 36px;
  font-weight: 700;
  letter-spacing: 0px;
  text-align: left;
  line-height: 51px;
}
.section-text-growth p {
  color: rgba(255, 255, 255, 1);
  font-family: "Lato", sans-serif;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0px;
  text-align: justify;
  line-height: 28px;
  margin: 0px;
}
.section-text-growth {
  padding: 30px 0 0 120px;
}

/* B2b Saas CSS */

.b2b-saas-main {
  background-color: RGB(235, 243, 254);
  padding-top: 100px;
  padding-bottom: 100px;
}
.b2b-container {
  max-width: 1083px;
}
.b2b-title {
  padding-left: 71px;
  color: rgba(0, 48, 86, 1);
  font-family: "Lato", sans-serif;
  font-size: 36px;
  font-weight: 700;
  letter-spacing: 0px;
  text-align: left;
  line-height: 51px;
}
.b2b-text p {
  margin-bottom: 0px;
  color: rgba(108, 108, 108, 1);
  font-family: "Lato", sans-serif;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0px;
  text-align: justify;
  line-height: 28px;
}
.b2b-text {
  padding: 30px 25px 0px 71px;
}

/* Case Study CSS */
.case-study-main {
  background-color: RGB(0, 48, 86, 1);
  padding-top: 127px;
  padding-bottom: 100px;
  position: relative;
}
.case-study-container {
  max-width: 1164px;
}
.case-study-title {
  padding-top: 31px;
  color: rgba(255, 255, 255, 1);
  font-family: "Lato", sans-serif;
  font-size: 36px;
  font-weight: 700;
  letter-spacing: 0px;
  text-align: left;
  line-height: 51px;
}
.case-study-text p {
  color: rgba(255, 255, 255, 1);
  font-family: "Lato", sans-serif;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0px;
  text-align: justify;
  line-height: 28px;
  margin-bottom: 0px;
}
.case-study-text {
  padding: 30px 169px 0 0;
}
.get-funded-main {
  border-radius: 8px 8px 8px 8px;
  box-shadow: 6px 6px 15px rgba(159, 164, 194, 0.4);
  background-color: rgba(255, 255, 255, 1);
  max-width: 1110px;
  padding: 45px 0;
  position: absolute;
  bottom: -70px;
  z-index: 99999;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
}
.get-funded-div {
  justify-content: center;
}
.get-funded-div div .form-control {
  width: 350px;
  height: 52px;
  margin-right: 30px;
  border-radius: 8px;
  box-shadow: -6px -6px 15px rgba(255, 255, 255, 0.643794),
    6px 6px 15px rgba(207, 211, 231, 0.6);
  background-color: rgba(255, 255, 255, 1);
  border: none;
}
.get-funded-div div .form-control input::placeholder {
  color: rgba(92, 104, 109, 1);
  font-family: "Lato", sans-serif;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0px;
  text-align: left;
  line-height: 28px;
}
.get-funded-btn {
  border-radius: 4px;
  background-color: rgba(34, 131, 251, 1);
  border: none;
  width: 192px;
  height: 52px;
  color: rgba(255, 255, 255, 1);
  font-family: "Lato", sans-serif;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0px;
  text-align: center;
}

.flexible-finance {
  position: relative;
  height: 603px;
  background-color: RGB(235, 243, 254);
  padding: 190px 0 120px 0;
}
.flexible-finance-container {
  max-width: 1015px;
}
.flexible-finance-left h1 {
  color: rgba(0, 48, 86, 1);
  font-family: "Lato", sans-serif;
  font-size: 28px;
  font-weight: 700;
  letter-spacing: 0px;
  text-align: left;
  line-height: 42px;
}
.flexible-finance-left h2 {
  padding: 10px 0 0 0;
  color: rgba(0, 48, 86, 1);
  font-family: "Lato", sans-serif;
  font-size: 24px;
  font-weight: 700;
  letter-spacing: 0px;
  text-align: left;
  line-height: 42px;
}
.flexible-finance-left {
  padding-right: 50px;
}
.flexible-finance-right {
  padding-left: 50px;
}
.flexible-finance-right h1 {
  color: rgba(0, 48, 86, 1);
  font-family: "Lato", sans-serif;
  font-size: 36px;
  font-weight: 700;

  letter-spacing: 0px;
  text-align: left;
  line-height: 51px;
}
.flexible-finance-right p {
  color: rgba(108, 108, 108, 1);
  font-family: "Lato", sans-serif;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0px;
  text-align: justify;
  line-height: 28px;
  padding-top: 30px;
  margin-bottom: 0;
}
.flexible-image-div {
  padding: 85px 0 0 15px;
}
.flexible-image-text {
  padding-right: 10px;
}
.flexible-image-div h1 {
  color: rgba(0, 48, 86, 1);
  font-family: "Lato", sans-serif;
  font-size: 24px;
  font-weight: 700;
  letter-spacing: 0px;
  text-align: right;
  line-height: 42px;
}
.flexible-image-div h2 {
  color: rgba(34, 131, 251, 1);
  font-family: "Lato", sans-serif;
  font-size: 24px;
  font-weight: 700;
  letter-spacing: 0px;
  text-align: right;
  line-height: 42px;
}

@media only screen and (max-width: 1299px) {
  .home-venture-detail {
    color: rgba(0, 48, 86, 1);
    font-family: "Lato", sans-serif;
    /* margin-top: 30px; */
    /* margin-left: 60px; */
    /* margin-bottom: 33px; */
    margin-right: 0px;
    font-size: 24px;
    font-weight: 400;
    letter-spacing: 0px;
    text-align: center;
    line-height: 28px;
    align-self: center;
  }
  .common-background {
    height: 320px;
  }
  .common-background {
    height: 345px;
  }
  .finance-container,
  .b2b-container,
  .case-study-container,
  .flexible-finance-container {
    max-width: 960px;
  }
  .section-title-growth {
    padding-left: 0px;
  }
  .section-text-growth {
    padding: 30px 0 0 0;
  }
  .case-study-image {
    width: -webkit-fill-available;
  }
  .case-study-text {
    padding: 30px 60px 0 0;
  }
  .get-funded-main {
    max-width: 900px;
  }
  .get-funded-div div .form-control {
    width: 300px;
  }
  .flexible-finance-right {
    padding-left: 29px;
  }
  .flexible-finance-left {
    padding-right: 0;
  }
  .why-Barlow-Marshall {
    height: 1040px;
  }
  .b2b-image {
    width: -webkit-fill-available;
  }
  .b2b-title {
    padding-left: 0;
  }
  .b2b-text {
    padding-left: 0;
  }
}
@media screen and (max-width: 1136px) {
  .home-venture-detail {
    font-size: 20px;
  }
}

@media only screen and (max-width: 1035px) {
  .home-venture-detail {
    font-size: 18px;
    line-height: 20px;
  }
}
@media only screen and (max-width: 984px) {
  .home-venture-detail {
    font-size: 16px;
    line-height: 20px;
  }
  .finance-container,
  .b2b-container,
  .case-study-container,
  .flexible-finance-container {
    max-width: 720px;
  }
  .section-first {
    margin-top: 60px;
  }
  .section-second {
    margin-top: 140px;
  }
  .section-title {
    margin-top: 50px;
  }
  .section-title-growth {
    padding-left: 0px;
    margin-top: 30px;
  }
  .b2b-title {
    padding-left: 0;
    margin-top: 30px;
  }
  .case-study-image {
    padding-top: 30px;
  }
  .case-study-main {
    padding-top: 100px;
  }
  .case-study-title {
    padding-top: unset;
  }
  .home-banner-sub-div {
    width: 100%;
  }
  .home-banner-main-title {
    font-size: 32px;
  }
  .home-banner-main-text {
    font-size: 16px;
    line-height: 26px;
  }
  .home-venture-content {
    width: 64%;
  }
  .get-funded-main {
    max-width: 720px;
  }
  .get-funded-div div .form-control {
    width: 200px;
    margin-right: 20px;
  }
  .get-funded-btn {
    width: 160px;
  }
  .flexible-image-div Image {
    width: 190px;
  }
}
@media only screen and (max-width: 768px) {
  .home-banner-sub-div {
    width: 100%;
  }
  .home-banner-main-title {
    font-size: 32px;
    white-space: break-spaces;
    text-align: center;
    line-height: 40px;
  }
  .home-venture-content {
    width: 84%;
  }
  .finance-feature {
    height: 100%;
  }
  .founder-content-left {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
  }
  .founder-content-right {
    margin-left: auto;
    margin-right: auto;
  }
  .why-Barlow-Marshall {
    height: 100%;
    padding-bottom: 50px;
  }
  .section-first {
    margin-top: 50px;
    margin-bottom: 20px;
  }
  .section-second {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .common-background {
    height: auto;
    padding-bottom: 20px;
  }
  .finace-image {
    width: 100%;
    height: 360px;
  }
  .finance-container,
  .b2b-container,
  .case-study-container,
  .flexible-finance-container {
    max-width: 540px;
  }
  .b2b-image {
    height: 284px;
    width: -webkit-fill-available;
  }
  .case-study-image {
    height: 380px;
  }
  .case-study-text {
    padding: 30px 0 0 0;
  }
  .get-funded-main {
    max-width: 500px;
  }
  .get-funded-div div .form-control {
    width: 100%;
    display: block !important;
    /* margin-right: 57px; */
  }
  .get-funded-div {
    justify-content: center;
    display: block !important;
    padding: 0 20px;
    height: auto;
  }
  .get-funded-div div .form-control {
    width: 100%;
    display: block !important;
    /* margin-right: 57px; */
    margin-top: 15px;
    margin-left: auto;
    margin-right: auto;
  }
  .get-funded-btn {
    width: 160px;
    margin-top: 20px;
    margin-right: auto;
    margin-left: auto;
    display: block;
  }
  .get-funded-main {
    padding: 20px 0;
    bottom: -120px;
  }
  .case-study-image {
    height: 380px;
    margin-bottom: 100px;
  }
  .flexible-finance {
    height: 100%;
  }
  .flexible-finance-right {
    padding-left: 0px;
  }
  .flexible-finance-left {
    display: grid;
  }
  .flexible-image-div {
    float: left !important;
  }
  .flexible-image-div {
    padding: 25px 0 25px 0px;
    margin-left: auto;
    margin-right: auto;
  }
}
@media only screen and (max-width: 576px) {
  .home-banner-sub-div {
    width: 100%;
  }
  .home-banner-sub-div {
    padding: 245px 20px;
  }
  .home-venture-content {
    width: 100%;
    border-radius: 0px;
  }
  .flexible-image-div img {
    width: auto;
    height: 83px;
  }
}
@media only screen and (max-width: 385px) {
  .home-venture-detail {
    font-size: 13px;
    line-height: 20px;
  }
  .home-venture-detail {
    margin-top: 0px;
    margin-left: 10px;
    margin-bottom: 0px;
    margin-right: 10px;
  }
  .home-venture-logo {
    opacity: 1;
    margin-right: 13px;
  }
  .founder-content {
    height: 100%;
    padding: 10px;
  }
  .finace-image {
    width: 100%;
    height: 360px;
  }
  .section-title-growth {
    padding-left: 0px;
    margin-top: -45px;
  }
  .section-text-growth p {
    line-height: 25px;
  }
  .finance-barlow {
    background-color: RGB(0, 48, 86, 1);
    padding-top: 0px;
    padding-bottom: 40px;
  }
  .b2b-title {
    padding-left: 0;
    margin-top: 10px;
  }
  .b2b-title {
    font-size: 30px;
  }
  .b2b-saas-main {
    background-color: RGB(235, 243, 254);
    padding-top: 0px;
    padding-bottom: 40px;
  }
  .b2b-text {
    padding: unset;
  }
  .case-study-main {
    padding-top: 40px;
  }
  .case-study-image {
    height: 380px;
    /* margin-bottom: 100px; */
  }
  .case-study-image {
    /* padding-top: 30px; */
  }
  .flexible-finance-left h1 {
    font-size: 25px;
    line-height: 30px;
  }
  .flexible-finance-left h2 {
    font-size: 21px;
    line-height: 32px;
  }
  .flexible-image-div img {
    width: auto;
    height: 45px;
  }
  .flexible-finance-right h1 {
    font-size: 28px;
  }
  .b2b-image {
    padding-top: 40px;
  }
}
/* terms and conditions css */
ol {
  list-style-type: none;
  counter-reset: item;
  margin: 0;
  padding: 0;
}
ol > li {
  display: table;
  counter-increment: item;
  margin-bottom: 0.6em;
}
ol > li:before {
  content: counters(item, ".") ". ";
  display: table-cell;
  padding-right: 0.6em;    
}
li ol > li {
  margin: 0;
}
li ol > li:before {
  content: counters(item, ".") " ";
}