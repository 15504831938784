
/* footer */
footer {
    padding: 30px 0;
    background-color: var(#fff);
    border-top: 1px solid #DBDCE5;
    position: absolute;
    /* bottom: 0; */
    left: 0;
    width: 100%;
}

.footer-social-media-icons {
    display: flex;
    padding: 0;
    margin: 0;
    align-items: center;
}

.footer-social-media-icons li {
    margin-right: 10px;
}

.footer-social-media-icons li:last-child {
    margin-right: 0;
}

.footer-social-media-icons a {
    display: inline-block;
    height: 30px;
    width: 30px;
    background-color: #D4E7FF;
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.active-link:active{
    color: #2283FB !important;
    font-weight: bold !important;
}
.active-link:focus{
    color: #2283FB !important;
    font-weight: bold !important;
}