.active-link {
    color: #2283FB !important;
    /* font-weight: bold !important; */
}
a{
    text-decoration: none;
}
/* body{
    transition: all 500ms ease 0s;
} */
/* nav {
    position: fixed;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 10rem;
  } */
header {
    width: 100%;
    /* padding: 16px 0; */
    position: fixed;
    top: 0;
    z-index: 1;
    transition: .4s;
    height: 80px;
    display: flex;
    align-items: center;
}

header.header-shadow {
    background-color: #fff;
    box-shadow: 6px 6px 15px 0 rgba(207, 211, 231, 0.6);
}

header .header-logo {
    max-width: 263px;
    display: inline-block;
}

header .header-logo img {
    width: 100%;
}

.header-nav .header-menu li a {
    transition: .4s;
}

.header-nav .header-menu li a:hover {
    color: var(--blue-color);
}

.header-nav > ul > li {
    margin-right: 2.82vw;
}

.header-nav > ul.button-links > li {
    margin-right: .5vw;
}

.header-nav > ul > li a {
    color: var(--primary-text-gray);
    font-weight: 500;
    display: block;
    align-items: center;
}

.header-nav > ul > li a i {
    font-weight: 600;
    font-size: 10px;
    margin-left: 10px;
}


p {
    line-height: 1.75;
    color: #5c686d;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    color: #003056;
    font-weight: 700;
}

.section-title h2 {
    font-size: 28px;
}

.section-title h3 {
    font-size: 24px;
}

.fa-brands {
    font-family: "Font Awesome 5 Brands";
    font-style: normal;
}

.white-text-color-section h1,
.white-text-color-section h2,
.white-text-color-section h3,
.white-text-color-section h4,
.white-text-color-section h5,
.white-text-color-section h6,
.white-text-color-section p {
    color: #fff;
}

b{
    font-weight: bold;
}

.btn-custom {
    margin: 0 3px;
    padding: 10px 31px;
    border: 2px solid #fff;
    border-radius: 25px;
    background-color: #f2f3f8;
    box-shadow: 6px 6px 15px 0 rgba(207, 211, 231, 0.5), -6px -6px 15px 0 rgba(255, 255, 255, 0.64);
}

.btn-custom.has-icon i {
    top: -2px;
    position: relative;
}

.btn-custom i {
    font-weight: 600;
    font-size: 10px;
    margin-left: 10px;
}

.btn-custom-2 {
    background-color: #2283fb !important;
    color: #fff !important;
}

.btn-custom-3 {
    background-color: #198754 !important;
    color: #fff !important;
}

.btn-custom-4 {
    background-color: #dc3545 !important;
    color: #fff !important;
}

.btn-custom-no-white-shadow {
    box-shadow: 6px 6px 15px 0 rgba(207, 211, 231, 0.5);
}

.custom-form .form-control, .custom-form .form-select {
    padding: 12px 15px;
    box-shadow: 6px 6px 15px 0 rgba(207, 211, 231, 0.5), -6px -6px 15px 0 rgba(255, 255, 255, 0.64);
    border-radius: 8px;
    border: 0;
}

.custom-form input::placeholder, .custom-form textarea::placeholder, .custom-form select::placeholder {
    /* color: var(--gray-color2);
    opacity: 1; */
    color: rgba(92, 104, 109, 1);
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0px;
    text-align: left;
    line-height: 28px;
}

.custom-form input:-ms-input-placeholder, .custom-form textarea:-ms-input-placeholder, .custom-form select:-ms-input-placeholder { 
    /* color: var(--gray-color2); */
    color: rgba(92, 104, 109, 1);
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0px;
    text-align: left;
    line-height: 28px;
}
  
.custom-form input::-ms-input-placeholder, .custom-form textarea::-ms-input-placeholder, .custom-form select::-ms-input-placeholder { 
    /* color: var(--gray-color2); */
    color: rgba(92, 104, 109, 1);
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0px;
    text-align: left;
    line-height: 28px;
}

/* banner div */
.banner-div {
    padding: 150px 0;
    position: relative;
}

.banner-content-wrap h1 {
    font-size: 52px;
    font-weight: 300;
    color: var(--primary-text-gray);
    margin-bottom: 20px;
}

.banner-content-wrap p {
    color: var(--secondary-text-gray);
    margin-bottom: 20px;
}

/* venture builder */
.venture-builder {
    position: absolute;
    bottom: -80px;
    width: 100%;
}

.venture-builder .container {
    padding: 25px 0;
    border-radius: 8px;
    background-color: #fff;
    box-shadow: 6px 6px 15px 0 rgba(159, 164, 194, 0.4);
}

/* how it works */
.how-it-works {
    padding: 210px 0 120px;
    background-color: var(--blue-color);
    /* background-image: url('../images/blue-bg-pattern.png'); */
    background-image: url('../../img/blue-bg-pattern.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

/* finance growth */
.finance-growth {
    padding: 120px 0 220px;
    position: relative;
}

/* usps */
.usps {
    position: absolute;
    bottom: -80px;
    width: 100%;
}

.usps .container {
    padding: 25px 0;
    border-radius: 8px;
    background-color: #fff;
    box-shadow: 6px 6px 15px 0 rgb(159 164 194 / 40%);
}

.usps span {
    font-weight: 700;
    font-size: 18px;
    color: #2283fb;
}

/* why barlow harshall */
.why-barlow-marshall {
    padding: 210px 0 120px;
    background-color: var(--blue-color);
    background-image: url('../../img/blue-bg-pattern.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.why-barlow-marshall h3 {
    font-size: 18px;
    margin-bottom: 20px;
}

/* case study home section */
.case-study-home-section {
    padding: 120px 0 220px;
    position: relative;
}

/* we understand */
.we-understand {
    padding: 120px 0;
}

/* flexible repayments */
.flexible-repayments {
    padding: 190px 0 120px;
    background-color: var(--blue-color);
    background-image: url('../../img/blue-bg-pattern.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

/* get funded */
.get-funded {
    position: absolute;
    bottom: -100px;
    width: 100%;
    padding: 40px 0;
}

.get-funded .container {
    padding: 40px 80px;
    border-radius: 8px;
    background-color: var(--white);
    box-shadow: 6px 6px 15px 0 rgba(159 164 194, 0.4);
}

/* LogIn */
.background{
    background-image: url('../../img/img-17.jpg') !important;
}
.div-height{
    min-height: 100vh !important;
}
body {
    padding-bottom: 0;
}


/* client index */

.nav-tabs {
    border-bottom: 0;
    margin-bottom: 15px;
}

.nav-tabs>li:first-child>button {
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;

}

.nav-tabs>li:last-child>button {
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;

}

.nav-tabs .nav-item {
    margin-bottom: 10px;
    flex: 1;
}

.nav-tabs .nav-item:last-child {
    margin-bottom: 0;
}

.nav-tabs .nav-link {
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 0px;
    /* border-radius: .25rem; */
    border-left: 1px solid #ccc;
    color: initial;
    padding: 0.75rem 1rem;
    white-space: nowrap;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
    border-color: #0d6efd;
    border-left: 1px solid #0d6efd;
    background-color: #0d6efd;
    color: #fff;
}

.nav-tabs .nav-link:hover {
    border-color: #0d6efd;
    border-left: 1px solid #0d6efd;
    color: #0d6efd;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active:hover {
    color: #fff;
}

/* .paging_simple_numbers {
    cursor: pointer;
}

.paging_simple_numbers span a {
    padding: 10px !important;
} */

/* #example_length {
    display: none;
} */

/* thead {
    background-color: #0d6efd;
    color: #fff;
} */

.bg-muted {
    border: 1px solid rgba(0,0,0,.125);
    border-radius: 0.25rem;
    background-color: #fff;
}

.border-line {
    border-right: 1px solid grey;
    height: fit-content;
}

/* .tr-border tr {
    border-style: none !important;
}
#example th {
    border-right: 1px solid #fff;
} */
th {
    background-color: #0d6efd !important;
    color: #fff !important;
    border-right: 1px solid #fff !important;
}

tr>th:last-child {
    border-right: none !important;
}

table thead th:first-child {
    border-top-left-radius: 0.5rem;
}

table thead th:last-child {
    border-top-right-radius: 0.5rem;

}

.tab-content>.tab-pane:not(.active),
.pill-content>.pill-pane:not(.active) {
    display: block;
    height: 0;
    overflow-y: hidden;
}

.table-bordered>:not(caption)>*>*,
tr {
    border: none;
    border-style: none;
}

.table-body {
    margin-top: 10px;

}

.table-body h5 {
    margin: 20px 0;
}

.table-body .table-bordered {
    background-color: #fff;
}

.paging-nav {
    text-align: center;
    padding-top: 2px;
}

.paging-nav a {
    margin: auto 5px;
    text-decoration: none;
    display: inline-block;
    padding: 1px 7px;
    color: #000;
    border-radius: 3px;
}

.paging-nav .selected-page {
    background: #0d6efd;
    color: #fff;
    font-weight: bold;
}

.paging-nav {
    width: 100%;
    margin: 0 auto;
    font-family: Arial, sans-serif;
}

tr>td {
    border-right: 2px solid #fff;
}

.cursor-pointer {
    cursor: pointer;
}
.chart-lebel {
    position: absolute;
    top: 130px;
    bottom: 0;
    left: 0;
    right: 0;
    font-size: 12px;
}

.chart-lebel-annual {
    position: absolute;
    top: 180px;
    bottom: 0;
    left: 0;
    right: 0;
    font-size: 12px;
}
.tooltip-lebel{
width: 110px;
background-color: black;
color: #fff;
text-align: center;
padding: 5px 0;
border-radius: 6px;
position: absolute;
bottom: 275px;
}
.Applied{
width: 110px;
background-color: #2283FB;
color: #fff;
text-align: center;
padding: 5px 0;
border-radius: 6px;
position: absolute;
bottom: 85px;
right: 13px;
}
.verification{
width: 110px;
background-color: #34c5a2;
color: #fff;
text-align: center;
padding: 5px 0;
border-radius: 6px;
position: absolute;
bottom: 85px;
}
.Awaiting{
width: 110px;
background-color: #c5344d;
color: #fff;
text-align: center;
padding: 5px 0;
border-radius: 6px;
position: absolute;
bottom: 275px;
right: 13px;
}

.check-list-container {
    font-size: 13px;
    margin: auto;
  }
  .check-list > div {
    font-weight: 500;
    color: #003056;
    font-size: 1rem;
  }
  .check-list {
    margin: 7px 0;
  }
  .check-list p {
    line-height: 1rem;
    color: #003056;
    display: none;
  }
  .active-check-list div {
    color: #2283fb;
    font-weight: 600;
  }
  .active-check-list p {
    display: block;
  }
  .outer-circle {
    border: 1px solid #2283fb;
    border-radius: 50%;
    height: 264px;
    display: flex;
    width: 264px;
    position: relative;
    margin: auto;
    MARGIN-RIGHT: 30PX;
  }
  .outer-dots{
      cursor: pointer;
  }
  
  .outer-dots:nth-child(1) {
    top: 34px;
right: 27px;
  }
  .outer-dots:nth-child(2) {
    top: 65px;
    right: 5px;

  }
  .outer-dots:nth-child(3) {
    top: 150px;
right: -5px;

  }
  .outer-dots:nth-child(4) {

    top: 181px;
right: 7px;


  }
  .outer-dots:nth-child(5) {
    top: 213px;
right: 26px;


  }
  .outer-dots{
      width: 15px;
      height: 15px;
      background-color: #000;
      border-radius: 50%;
      position: absolute;
      animation: translateDiv 5s infinite;
  }
  .outer-dots-active1{
 
    width: 25px;
    top: 31px !important;
    right: 19px !important;
    height: 25px;
    background-color: #006ff7;
    transition: all 500ms ease 0s;
    animation: translateDiv 5s infinite;


  }
  .outer-dots-active2{
    width: 25px;
    top: 63px !important;
    right: 0px !important;
    height: 25px;
    background-color: #006ff7;
    transition: all 500ms ease 0s;

    animation: translateDiv 5s infinite;

    
  }
  .outer-dots-active3{
    width: 25px;
    top: 92px !important;
    right: -11px !important;
    height: 25px;
    background-color: #006ff7;
    transition: all 500ms ease 0s;

    animation: translateDiv 5s infinite;

  }
  .outer-dots-active4{
    width: 25px;
    top: 123px !important;
    right: -12px !important;
    height: 25px;
    background-color: #006ff7;
    transition: all 500ms ease 0s;
    animation: translateDiv 5s infinite;


  }
  .outer-dots-active5{
    width: 25px;
    top: 154px !important;
    right: -6px !important;
    height: 25px;
    background-color: #006ff7;
    transition: all 500ms ease 0s;
    animation: translateDiv 5s infinite;

  }

  .outer-dots-animation
  {
  transition: all 500ms ease 0s;
    animation: translateDiv 5s infinite;
  }
  .outer-dots-active2-shift{
    top: 122px !important;
    right: -7px !important;
    transition: all 500ms ease 0s;
    animation: translateDiv 5s infinite;

  }
  .outer-dots-active3-shift{
    top: 96px !important;
    right: -5px !important;
    transition: all 500ms ease 0s;
    animation: translateDiv 5s infinite;

  }
  .outer-dots-active4-shift{
    top: 129px !important;
    right: -8px !important;
    transition: all 500ms ease 0s;
    animation: translateDiv 5s infinite;
  }
  @keyframes translateDiv {
    from {transform: rotateY(100)}
    to {transform: rotateY(200px);}
  }

  /* @keyframes lowwerTOupper {
    from {transform: rotate(100)}
    to {transform: rotate(200px);}
  } */


  
  .inner-circle {
    margin: 30px;
    border: 1px solid #2283fb;
    border-radius: 50%;
    background-color: #ebf3fa;

    width: 200px;
    display: flex;
    margin: auto;
    height: 200px;
  }
  .inner-circle img {
    margin: auto;
    width: 120px;
  }
  .previous-active{
    color: #006ff7;
    background: #006ff7;
  }
  .previous-active-text>div{
    color: #006ff7;
  }

/* View-Offer */
.sigCanvas {
    /* display: flex; */
    justify-content: end;
    /* margin: auto; */
    background-color: #f6f6f6 !important;
    border: 1px solid black;
    cursor: crosshair;
    align-items: end;
    margin-left:0 !important;
    margin-right:40px !important;
}
.signature-container{
    margin: 10px 0;
    border-radius: 10px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}
canvas {
    /* background-color: #fff; */
    margin: 0 auto;
}

button#clear {
    width: 80px;
}

.send-query {
    text-align: right;
}

.w-40 {
    width: 40% !important;
}

.w-60 {
    width: 60% !important;
}

.accordion-button {
    background-color: #0d6efd !important;
    color: #fff !important;
}

.accordion-button::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e") !important;
}

.table-font-color {
    color: #0a4c85;
}

.dierector-content {
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 8px;
    border-left: 15px solid #003056;
    padding: 12px;
    text-align: center;
    font-size: 14px;
}

hr:not([size]) {
    height: 2px;
}

/* table css start */
tr:nth-child(even) {
    background-color: #F8F9FF;
}

.main-container {
    width: 80% !important;
  }
  .overflow {
    overflow-x: hidden;
  }
  /* #headingTwo {
    background-color: #0d6efd !important;
    height: 35px;
    color: white;
} */

/* #headingOne {
    background-color: #0d6efd !important;
    height: 35px;
    color: white;
}

#headingThree {
    background-color: #0d6efd !important;
    height: 35px;
    color: white;
} */

.ledgerbutton {
    margin-left: 30px;
    width: 180px;

}

.modularaccount {
    margin: 0px 30px 0px 30px;
}

.nonmodularacount {
    margin: 0px 30px 0px 30px;
}

.accordion-header {
    font-size: 16px;
    padding-top: 7px;
    padding-left: 5px;
}

.Unsettled1 {
    padding: 12px 12px;
    /* text-align: center; */
    font-size: 16px;
}

.Settled1 {
    padding: 12px 22px;
    /* text-align: center; */
    font-size: 16px;
}


/* Ledger */
.top-detail-row strong {
    /* color: #0d6efd; */
    color: #003056;
    font-weight: normal;
}

.top-detail-row span {
    color: #0d6efd;
    font-weight: bold;
    /* color: #003056; */
}


tr>th:last-child {
    border-right: none !important;
}

table thead th:first-child {
    border-top-left-radius: 0.5rem;
}

table thead th:last-child {
    border-top-right-radius: 0.5rem;

}

.tab-content>.tab-pane:not(.active),
.pill-content>.pill-pane:not(.active) {
    display: block;
    height: 0;
    overflow-y: hidden;
}

/* tr:nth-child(even) {
    background-color: #F8F9FF;
} */

.table-bordered>:not(caption)>*>*,
tr {
    border: none;
    border-style: none;
    border-top: 1px solid #f2f3f8 !important;
}

.table-body {
    margin-top: 10px;

}

.table-body h5 {
    margin: 20px 0;
}

.table-body .table-bordered {
    background-color: #fff;
}

.table>tbody {
    /* border: 1px solid #e7e7e7; */
    border: 1px solid #f2f3f8;
    border-top: none;
}
tr> td{
    border-right: 2px solid #fff;   
}
th {
    background-color: #0d6efd !important;
    color: #fff !important;
    border-right: 1px solid #fff !important; 
}
tr>th:last-child{
    border-right: none !important; 
}

table thead th:first-child {
    border-top-left-radius: 0.5rem;
}
table tbody tr> td:first-child {
    white-space: nowrap;
}

table thead th:last-child {
    border-top-right-radius: 0.5rem;

}
.table-bordered {
background-color: #fff;
}
.table>:not(:first-child) {
    border-top: none;
}
/* Info tabs css */

.paging-nav {
    text-align: center;
    padding-top: 2px;
}

.paging-nav a {
    margin: auto 5px;
    text-decoration: none;
    display: inline-block;
    padding: 1px 7px;
    color: #000;
    border-radius: 3px;
}

.paging-nav .selected-page {
    background: #0d6efd;
    color: #fff;
    font-weight: bold;
}

.paging-nav {
    width: 100%;
    margin: 0 auto;
    font-family: Arial, sans-serif;
}

tr>td {
    border-right: 2px solid #fff;
}

.cursor-pointer {
    cursor: pointer;
}

.fa-arrow-left,
.fa-arrow-right {
    -webkit-text-stroke: 1px white;
}

.chart-container {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
}

.header-chart {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0 48px 0 10px;


    justify-content: space-between;
}

.header-chart h5 {
    width: 100%;
}

.chart-section {
    white-space: nowrap;
    width: 100%;
    align-items: center;
    display: flex;
    justify-content: flex-end;
    /* align-items: flex-end; */
    flex-direction: row;
}

.avg-title {
    white-space: nowrap;
    font-weight: bold;

}

.avg-value {
    background-color: #2283FB;
    color: #fff;
    margin: 5px;
    padding: 5px 26px;
    border-radius: 0.25rem;
}

.chart-wrapper {
    width: 100%;
}

.charts-title {
    margin-top: 20px;
    margin-left: 27px;
    font-weight: bold;
    font-size: 18px;
}

.border-line {
    border-right: 1px solid grey;
    height: fit-content;
}
.client-overview{
    flex: 1;
}
.client-overview h4{
    color:#006FF7 !important;
}
.client-overview p{
    margin: 0;
    color: #003056;
    font-weight: 500;

}

/* info tabs */
.nav-tabs .nav-link .count {
    font-size: 12px;
    padding: 0 5px;
    display: inline-block;
    border: 1px solid #8097AA;
    margin-left: 10px;
}
.nav-tabs .nav-link.active .count {
    display: inline-block;
    border: 1px solid #fff;
    background-color: #fff;
    color: #2283FB;
}
.cursor-pointer{
    cursor: pointer;
}
/* Get Funded */
/* th {
    background-color: var(--blue-color2) !important;
    color: #fff !important;
} */

.btn-primary {
    background-color: var(--blue-color2);
    border-color: var(--blue-color2);
}

/* .dierector-content {
    background-color: var(--gray-color);
    border: 1px solid #ccc;
    border-radius: 8px;
    border-left: 15px solid var(--primary-text-gray);
    padding: 12px;
} */

.dierector-content ul {
    margin: 0;
}

.dierector-content ul li {
    margin-bottom: 8px;
}

.dierector-content ul li:last-child {
    margin-bottom: 0;
}

.blue {
    border-left: 12px solid #003056 !important;
    border-radius: 8px !important;
    background-color: rgb(175 168 168 / 11%) !important;
}

.blue:hover {
    border-color: #003056 !important;
    border-radius: 8px !important;
}

.active.blue {
    border-color: #003056 !important;
    border-radius: 8px !important;
    background-color: #fff !important;
    color: black !important;
}

.check:checked {
    background-color: #003056 !important;
}
.btn-prev {
    border: 1px solid #9FA4C2 !important;
    color: rgba(0, 47, 86, 0.65);
}
.btn-prev:hover {
    border: 1px solid #9FA4C2;
}
.nav-tabs .nav-links {
    background-color: rgb(175 168 168 / 11%) !important;
    border: 1px solid #ccc !important;
    padding: 12px !important;
    border-radius: 8px !important;
    border-left: 12px solid #ccc !important;
    color: initial !important;
}
.nav-tabs .nav-item.show .nav-links,
.nav-tabs .nav-links.active {
    border-color: #0d6efd !important;
    /* border-left: 1px solid #0d6efd !important; */
    background-color: #fff !important;
    color: black !important;
}

.nav-tabs .nav-links:hover {
    border-color: #0d6efd !important;
    /* border-left: 1px solid #0d6efd !important; */
    color: #0d6efd !important;
}

.nav-tabs .nav-item.show .nav-links,
.nav-tabs .nav-links.active:hover {
    color: #0d6efd !important;
    /* border-left: 1px solid #0d6efd !important; */
}


/* Admin Dashboard */

.nav-tabs .nav-link .count {
font-size: 12px;
padding: 0 5px;
display: inline-block;
border: 1px solid #8097AA;
margin-left: 10px;
}
.nav-tabs .nav-link.active .count {
display: inline-block;
border: 1px solid #fff;
background-color: #fff;
color: #2283FB;
}
.table-body h5 {
    margin: 20px 0;
}
tr> td{
    border-right: 2px solid #fff;   
}
table tbody tr> td:first-child {
    white-space: nowrap;
}

.table-bordered {
background-color: #fff;
}
.resp-height{
    height: calc(100vh - 200px);
}

.dashboard-tabs>ul{
    border-bottom: 0 !important;
    margin-bottom: 15px !important;
}
.dashboard-tabs>ul>li{
    background-color: #fff ;
    border: 1px solid #ccc ;
    border-radius: 0px !important;
    text-align: center !important;
    border-left: 1px solid #ccc !important;
    color: #000 ;
    color: initial ;
    padding: 0.75rem 1rem !important;
}
/* .dashboard-tabs ul li:active{
    background-color: #0d6efd !important;
    border-color: #0d6efd !important;
    color: #fff !important;
    border-radius: 0 !important;
    border-left: 1px solid #0d6efd !important;
} */
.dashboard-tabs>ul>li:focus{
    background-color: #0d6efd !important;
    border-color: #0d6efd !important;
    color: #fff !important;
    border-radius: 0 !important;
    border-left: 1px solid #0d6efd !important;
}
.dashboard-tabs>ul>li:first-child {
    border-top-left-radius: 0.25rem !important;
    border-bottom-left-radius: 0.25rem !important;
}
.dashboard-tabs>ul>li:last-child {
    border-top-right-radius: 0.25rem !important;
    border-bottom-right-radius: 0.25rem !important;
}
.dashboard-tabs>ul>li.react-tabs__tab--selected{
    background-color: #0d6efd !important;
    border-color: #0d6efd !important;
    color: #fff !important;
    border-radius: 0 !important;
    border-left: 1px solid #0d6efd !important;
}


/* Client Navbar */
.navbar__link:hover {
    color: #29b6f6 !important;
}
.navlink>ul>li>.active{
    color:#2283FB  !important;
   }
   .table-striped>tbody>tr:nth-child(odd){
background-color: #fff !important;
--bs-table-accent-bg: white;
}
.table.mdb-dataTable tbody > tr:not(.selected):hover{
    background-color: #ffffff;
    --bs-table-accent-bg: #0d6efd14;
       
   }
   .action-center table>tbody>tr>td{
text-align: center;
   }


.css-1okebmr-indicatorSeparator{
    width: 0!important;
}
.css-tlfecz-indicatorContainer{
    color: #212529 !important;
    padding: 0!important;
}
.css-14el2xx-placeholder{
    color: #212529 !important;
}
.css-c8odyh-control{
    padding: 7px 10px 7px 5px!important;
    box-shadow: 6px 6px 15px 0 rgb(207 211 231 / 50%), -6px -6px 15px 0 rgb(255 255 255 / 64%) !important;
    border-radius: 8px !important;
    border: 0 !important;
}

.css-1s2u09g-control{
    border: 0 !important;
}
.btn-custom-blue {
    background: #006FF7;
    border: 1px solid #006FF7;
    color: #fff;
}
.btn-custom-blue:hover {
    border: 1px solid #006FF7;
    background: #006FF7;
    color: #fff;
}
.btn-custom1 {
    border-radius: 7px !important;
    padding: 6px 31px;
    margin: 5px 5px;
}
.btn-custom-orange {
    border: 1px solid #D91E18;
    color: #D91E18;
}
.btn-custom-orange:hover {
    border: 1px solid #D91E18;
    color: #fff;
    background: #D91E18;
}
.hide-pagination .mdb-dataTables_paginate{
    display: none;
}
.hide-pagination .mdb-datatable-info{
    display: none !important;
}
.section-height{
    min-height: 72vh;
}
.inputClass::placeholder {
    color: #000 !important;
    opacity: 1;
}

.override{
    display: block !important;
  margin: 0 auto !important;
  border-color: green !important;
}

spinnerStyle {
    flex: 1;
    margin-top:240;
    justify-content: 'center';
    align-items:'center';
}
.ineligible-info{
    display: flex;
    border-radius: 10px;
    text-align: center;
    width: 65%;
    padding: 40px;
    /* border: 1px solid #000;
     */
     box-shadow: rgb(177 200 232) 0px 5px 15px;
    margin: 100px auto;
    justify-content: center;
    align-items: center;
}
.react-tel-input .form-control{
    position: relative;
    font-size: 14px;
    letter-spacing: .01rem;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    padding-left: 48px;
    margin-left: 0;
    background: #FFFFFF;
    border: none !important;
    border-radius: 5px;
    line-height: 25px;
    height: 100% !important;
    width: 100% !important;
    outline: none !important;
    padding-top: 16px !important;
    padding-bottom: 16px !important;
}

.form-select > div > div{
    padding-left: 0px !important;
}
.react-tel-input .form-control.invalid-number{
    background-color: #fff !important;
}
.disable-tabs{
    cursor: not-allowed !important;
    border: 1px solid #999999 !important;
    background-color: #cccccc !important;
    color: #666666 !important;
}
.input-group-text{
    padding: 12px 15px;
    box-shadow: 6px 6px 15px 0 rgb(207 211 231 / 50%), -6px -6px 15px 0 rgb(255 255 255 / 64%);
    border: 0;
    align-items: center;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    text-align: center;
    white-space: nowrap;
    background-color: #e9ecef;
    border-radius: 0.25rem;
    display: block !important;
}
.ledger-tabs li button{
    width: 100% !important;
}
/* .offer-message{
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    display: flex;
    padding: 10px;
    flex-direction: column;
    border-radius: 10px;
    border: 1px solid rgba(0,0,0,.125);
    margin: 10px 0;
} */
.offer-container{
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    background-color: #fff;
    padding: 20px;
    border-radius: 5px 5px 0 0;
    align-items: center;
}
.offer-status{
    font-weight: 700;
    opacity: 1;
color: rgba(179,0,22,1);
font-size: 22px;
padding: 5px;
font-weight: 700;
font-style: normal;
line-height: 28px;
}
.offer-rejected {
    color: rgba(179,0,22,1)!important;
    text-transform: capitalize;
}
.offer-accepted {
    text-transform: capitalize;
    color: #74C120!important;
}
.offer-query {
    text-transform: capitalize;
    color: #0d6efd!important;
}
.offer-pending {
    text-transform: capitalize;
    color: #F5A623!important;
}
.offer-withdraw_offer {
    text-transform: capitalize;
    color: rgba(179,0,22,1)!important;
}
.offer-description{
    opacity: 1;
    color: rgba(0,48,86,1);
    font-family: "Lato-Regular";
    font-size: 16px;
    font-weight: 400;
    font-style: normal;
    line-height: 20px;
}

.filter-transaction-container{
    display: flex;
    
}
table.mdb-dataTable thead .sorting:before, table.mdb-dataTable thead .sorting_asc:before, table.mdb-dataTable thead .sorting_desc:before, table.mdb-dataTable thead .sorting_asc_disabled:before, table.mdb-dataTable thead .sorting_desc_disabled:before, table thead.mdb-dataTable-head .sorting:before, table thead.mdb-dataTable-head .sorting_asc:before, table thead.mdb-dataTable-head .sorting_desc:before, table thead.mdb-dataTable-head .sorting_asc_disabled:before, table thead.mdb-dataTable-head .sorting_desc_disabled:before{
    right: 10px !important;
    left: 0 !important;
    bottom: 0 !important;
    top: 0;
    margin: auto;
    display: flex !important;
    justify-content: end;
    align-items: center;
}
table.mdb-dataTable thead .sorting_asc:before, table.mdb-dataTable thead .sorting_desc:after, table thead.mdb-dataTable-head .sorting_asc:before, table thead.mdb-dataTable-head .sorting_desc:after{
    right: 10px !important;
    left: 0 !important;
    top: 0;
    bottom: 0 !important;
    margin: auto;
    display: flex !important;
    justify-content: end;
    align-items: center;
}
table.mdb-dataTable thead .sorting_asc:before, table.mdb-dataTable thead .sorting_desc:after, table thead.mdb-dataTable-head .sorting_asc:before, table thead.mdb-dataTable-head .sorting_desc:after{
    right: 10px !important;
    left: 0 !important;
    top: 0;
    bottom: 0 !important;
    margin: auto;
    display: flex !important;
    justify-content: end;
    align-items: center;
} 

.hide-reason p:last-child {
    background: #ff0000;
  }

.hide-reason table thead th:last-child{
    display: none;
}
.hide-reason table tbody td:last-child{
    display: none;
}