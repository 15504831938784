.accordion-header {
  font-size: 15px;
  padding-top: 5px;
  padding-left: 5px;
  padding-bottom: 5px;
  /* font-size: 16px;
  padding-top: 7px;
  padding-left: 5px;
  padding-bottom: 10px; */
  box-shadow: inset 0 -1px 0 rgb(0 0 0 / 13%) !important;
}
#yapilyTabContent .accordion-button {
  background: white !important;
  color: black !important;
  padding: 0 !important;
  min-height: 50px;
}
#yapilyTabContent .accordion-button:not(.collapsed) {
  box-shadow: none;
}
#yapilyTabContent .accordion-button:hover {
  z-index: 0 !important;
}
.img-das-yapi {
  height: auto;
  max-height: 50px;
  width: auto;
  max-width: 100px;
  object-fit: contain;
  border: 1px solid white;
}
.btn-add-account {
  position: relative;
  float: right;
  right: 70px;
  margin-top: -45px;
  background: #006FF7;
  border: 1px solid #006FF7;
  color: #fff;
  box-shadow: 6px 6px 15px 0 rgb(207 211 231 / 50%);
  border-radius: 7px !important;
}
.btn-add-account:hover{ color: #fff; }
.btn-yapi-sync {
  /* float: right; */
  position: relative;
  background: #006FF7;
  border: 1px solid #006FF7;
  color: #fff;
  box-shadow: 6px 6px 15px 0 rgb(207 211 231 / 50%);
  border-radius: 7px !important;
}
.btn-yapi-sync:hover{ color: #fff; }
.btn-consent-extend {
  float: right;
  background: #006FF7;
  border: 1px solid #006FF7;
  color: #fff;
  box-shadow: 6px 6px 15px 0 rgb(207 211 231 / 50%);
  border-radius: 7px !important;
  margin-left: 25px;
}
.btn-consent-extend:hover{ color: #fff; }
.btn-consent-revoke {
  float: right;
  color: #fff;
  margin-right: 5px;
  background: #006FF7;
  border: 1px solid #006FF7;
  box-shadow: 6px 6px 15px 0 rgb(207 211 231 / 50%);
  border-radius: 7px !important;
}
.btn-consent-revoke:hover{ color: #fff; }

.chkCenter .form-check {
  display: inline-block !important;
}
.chkCenter .form-check .form-check-label, .chkCenter .form-check .form-check-input {
  cursor: pointer !important;
}
.with-overlay {
  position: absolute;left: 0; top: 0; right: 0; bottom: 0;z-index: 2;background-color: rgba(255,255,255,0.8);
}
.with-overlay-content {
  position: absolute;top: 25%;left: 0;right: 0;text-align: center;color: #555;
}