body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', 'Lato',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.fullscreen-enabled {
  background-color: #fff !important;
}

::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}
::-webkit-scrollbar-track
{
  background: rgba(0, 0, 0, 0.1);
}
::-webkit-scrollbar-thumb
{
  background: rgba(0, 0, 0, 0.3);
}
/* .scrollbar-thin {
  scrollbar-width: thin;
}
.thin_scroll{
  scrollbar-width: thin;
} */

.search-banks {
  width: 66%;
  border-radius: 10px;
  border: 1px solid #ada9a9;
  padding: 1% 6%;
  margin: 1% 1%;
  background-image: url(https://www.pngarea.com/pngm/109/1164446_searchicon-png-search-icon-png-download.png);
  background-size: contain;
  background-repeat: no-repeat;
  outline: 0;
  background-position: 12px;
  background-size: 16px;
}
.noti-alert {
  padding: 10px 10px !important;
  margin-bottom: 10px !important;
}
.swal2-info-title {
  color: red !important;
}
.discHeader {
  margin: 10px 10px 0px 10px;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  width: 97%;
  cursor: pointer;
}
.disclaimerYapily {
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 20px;
  margin: 0 10px;
  width: calc(100% - 20px);
}